var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('my-page-header',{attrs:{"title":_vm.$t('page.title')}}),_vm._v(" "),_c('a-card',{attrs:{"bordered":false,"loading":_vm.pageLoading}},[_c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('input',{staticClass:"hidden",attrs:{"name":"fakeusernameremembered"}}),_vm._v(" "),_c('input',{staticClass:"hidden",attrs:{"type":"password","name":"fakepasswordremembered"}}),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.current_password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'current_password' ,
							{ rules: [
								{ required: true, message: _vm.$tt('validate.required','field.current_password') },
								{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: _vm.$t('common.password.pattern') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'current_password' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true, message: $tt('validate.required','field.current_password') },\n\t\t\t\t\t\t\t\t{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\\d).{6,40}$/ ,message: $t('common.password.pattern') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"new-password","type":"password","placeholder":_vm.$t('field.current_password.placeholder')}},[_c('ion-icon',{staticClass:"anticon",attrs:{"slot":"prefix","name":"key-outline"},slot:"prefix"})],1)],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.new_password'),"extra":_vm.$t('common.password.pattern')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'new_password' ,
							{ rules: [
								{ required: true, message: _vm.$tt('validate.required','field.new_password') },
								{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: _vm.$t('common.password.pattern') },
							] },
						]),expression:"[\n\t\t\t\t\t\t\t'new_password' ,\n\t\t\t\t\t\t\t{ rules: [\n\t\t\t\t\t\t\t\t{ required: true, message: $tt('validate.required','field.new_password') },\n\t\t\t\t\t\t\t\t{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\\d).{6,40}$/ ,message: $t('common.password.pattern') },\n\t\t\t\t\t\t\t] },\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"new-password","type":"password","placeholder":_vm.$t('field.new_password.placeholder')}},[_c('ion-icon',{staticClass:"anticon",attrs:{"slot":"prefix","name":"key-outline"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('field.confirmed_password')}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
							'confirmed_password' ,
							{
								rules: [
									{ required: true, message: _vm.$tt('validate.required','field.confirmed_password') },
									{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\d).{6,40}$/ ,message: _vm.$t('common.password.pattern') },
									{ validator: _vm.validateConfirmPassword } ,
								],
							},
						]),expression:"[\n\t\t\t\t\t\t\t'confirmed_password' ,\n\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t{ required: true, message: $tt('validate.required','field.confirmed_password') },\n\t\t\t\t\t\t\t\t\t{ pattern: /^(?=.*[a-zA-Z#$^+=!*()@%&])(?=.*\\d).{6,40}$/ ,message: $t('common.password.pattern') },\n\t\t\t\t\t\t\t\t\t{ validator: validateConfirmPassword } ,\n\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}],attrs:{"autocomplete":"new-password","type":"password","placeholder":_vm.$t('field.confirmed_password.placeholder')}},[_c('ion-icon',{staticClass:"anticon",attrs:{"slot":"prefix","name":"key-outline"},slot:"prefix"})],1)],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_c('a-button',{staticStyle:{"margin-right":"4px"},attrs:{"size":"large","icon":"left","disabled":_vm.formLoading},on:{"click":_vm.goBack}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.$t('common.back'))+"\n\t\t\t\t\t")]),_vm._v(" "),_c('a-button',{staticClass:"btn-submit",attrs:{"type":"info","html-type":"submit","size":"large","loading":_vm.formLoading}},[_c('a-icon',{attrs:{"type":"save"}}),_vm._v(" "+_vm._s(_vm.$t('change_password.button'))+"\n\t\t\t\t\t")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }